<template>
    <v-container fluid class="white shadow1 rounded">
         <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="getBankLedger">
                    <v-row>
                        <v-col cols="5">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-row no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                        <v-col cols="4">{{ $store.translate('Date From') }}</v-col>
                                        <v-col cols="8" class="pl-1">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="filter.dateFrom"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="filter.dateFrom"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="6">
                                    <v-row class="pl-3" no-gutters style="margin-top: 3px;color: #000;align-items: center;">
                                        <v-col cols="4">{{ $store.translate('Date To') }}</v-col>
                                        <v-col cols="8">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="filter.dateTo"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="filter.dateTo"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="1" class="d-flex align-center">
                            <v-btn type="submit" color="text_bg_fave" :loading="loading">{{ $store.translate('Search') }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </v-form>
            </v-col>
        </v-row>
        <v-row class="py-2">
            <v-col cols="12">
                <v-btn height="26px" @click.prevent="print" class="mb-2" v-if="$store.getters['bankTransaction/all_ledgers'].length != 0">Print</v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="tableHeaders"
                    class="custom-data-table white shadow1 rounded px-4 py-4" 
                    :items="$store.getters['bankTransaction/all_ledgers']"
                >
                <template v-slot:[`item`]="{ item }">
                    <tr>
                        <td style="text-align: left;">{{ item.sl }}</td>
                        <td style="white-space: nowrap;text-align: left;">{{ item.date}}</td>
                        <td style="text-align: left;">{{ item.description }}</td>
                        <td style="text-align: right;">{{ item.cash_in}}</td>
                        <td style="text-align: right;">{{ item.cash_out}}</td>
                        <td style="text-align: right;">{{ item.balance}}</td>
                    </tr>
                </template>
                <template slot="body.prepend">
                    <tr class="pink--text"> 
                        <td class="title" style="text-align: left;" colspan=2></td>
                        <td class="title" style="text-align: left;" colspan="3">
                            {{ $store.translate('Opening Balance') }}
                        </td>
                        <td class="title" style="text-align: right;">{{ $store.getters['bankTransaction/openingBalance'] }}</td>
                    </tr>
                </template>
                <template slot="body.append">
                    <tr class="pink--text"> 
                        <td class="title" style="text-align: left;" colspan=2></td>
                        <td class="title" style="text-align: left;">
                            Totals of all({{ lodash.size($store.getters['bankTransaction/all_ledgers']) }}) record
                        </td>
                        <td class="title" style="text-align: right;">{{ Number(sumField('cash_in', $store.getters['bankTransaction/all_ledgers'])).toFixed(2) }}</td>
                        <td class="title" style="text-align: right;">{{ Number(sumField('cash_out', $store.getters['bankTransaction/all_ledgers'])).toFixed(2) }}</td>
                        <td class="title" style="text-align: right;">{{ Number(
                            sumField('cash_in', $store.getters['bankTransaction/all_ledgers']) - 
                            sumField('cash_out', $store.getters['bankTransaction/all_ledgers'])
                        ).toFixed(2) }}</td>
                    </tr>
                </template>
                </v-data-table> 
            </v-col>
        </v-row>

        <div style="display: none;" id="invoiceContent">
            <v-simple-table class="bordered-table">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th style="text-align: left;padding:10px 16px;">{{ $store.translate('Serial') }}</th>
                            <th style="text-align: left;padding:10px 16px;">{{ $store.translate('Date') }}</th>
                            <th style="text-align: left;padding:10px 16px;">{{ $store.translate('Description') }}</th>
                            <th style="text-align: right;padding:10px 16px;">{{ $store.translate('Deposit') }}</th>
                            <th style="text-align: right;padding:10px 16px;">{{ $store.translate('Withdraw') }}</th>
                            <th style="text-align: right;padding:10px 16px;">{{ $store.translate('Balance') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td colspan="3">{{ $store.translate('Opening Balance') }}</td>
                            <td style="text-align: right;">{{ $store.getters['bankTransaction/openingBalance'] }}</td>
                        </tr>
                        <tr v-for="(ledger, ind) in $store.getters['bankTransaction/all_ledgers']" :key="ind">
                            <td style="text-align: left;">{{ ind + 1}}</td>
                            <td style="white-space: nowrap;text-align: left;">{{ ledger.date}}</td>
                            <td style="text-align: left;">{{ ledger.description }}</td>
                            <td style="text-align: right;">{{ ledger.cash_in}}</td>
                            <td style="text-align: right;">{{ ledger.cash_out}}</td>
                            <td style="text-align: right;">{{ ledger.balance}}</td>
                        </tr>
                        <tr class="pink--text"> 
                            <td class="title" style="text-align: left;" colspan=2></td>
                            <td class="title" style="text-align: left;">
                                Totals of all({{ lodash.size($store.getters['bankTransaction/all_ledgers']) }}) record
                            </td>
                            <td class="title" style="text-align: right;">{{ Number(sumField('cash_in', $store.getters['bankTransaction/all_ledgers'])).toFixed(2) }}</td>
                            <td class="title" style="text-align: right;">{{ Number(sumField('cash_out', $store.getters['bankTransaction/all_ledgers'])).toFixed(2) }}</td>
                            <td class="title" style="text-align: right;">{{ Number(
                                sumField('cash_in', $store.getters['bankTransaction/all_ledgers']) - 
                                sumField('cash_out', $store.getters['bankTransaction/all_ledgers'])
                            ).toFixed(2) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </v-container>
</template>

<script>
import { styles, invoiceStyle } from '../../composables/usePrint';
export default {
    name: 'AllTransactions',

    data: () => ({
        filter: {
            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
        },
        tableHeaders: [
            { text: 'Serial', value: 'account_number', align: 'start', },
            { text: 'Date', value: 'account_number', align: 'start', },
            { text: 'Description', value: 'account_number', align: 'start', },
            { text: 'Deposit', value: 'account_number', align: 'end', },
            { text: 'Withdraw', value: 'account_number', align: 'end', },
            { text: 'Balance', value: 'account_number', align: 'end', },
        ],
        bank: null,
        loading: false
    }),


    methods: {
        sumField(item_name, items=[]) {
            return items.reduce((a, b) => a + (Number(b[item_name]) || 0), 0)
        },
        async getBankLedger() {
            this.loading = true;
            await this.$store.dispatch('bankTransaction/getAllLedger', this.filter)
            this.loading = false;
        },
        async print(){
            let invoiceContent = document.querySelector('#invoiceContent').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Investigation Payment Slip</title>
                    <style>
                        ${invoiceStyle}
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            <div style="text-align:center;font-weight:bold;font-size:20px;">All transaction ledger</div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${invoiceContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody> 
                            </table> 

                        </div>
                    </div>    
                    
                </body>
                </html>
            `);

            printWindow.moveTo(0, 0);
            
            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: 3px !important;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        font-size: 11px;
        padding: 0px 1px;
        // background: #061e95;
        // color: #fff;
        background: var(--theme_lighter);
        // border: 1px solid #ccc;
        text-align: center;
        // height: 24px !important;
    }
    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        // height: 20px !important;
        padding: 0px 5px !important;
        font-size: 12px !important;
        border: 1px solid #ccc !important;
        text-align: center;
    }
</style>